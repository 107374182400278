import React from 'react'
import StepWrapper from '../Terminabfrage/StepWrapper'
import RadioGroup from '../Forms/RadioGroup'
import Button from '../Button/Button'

const Step1 = ({slugifyString, clientOptions, handleRadioChange, anmeldung, setAnmeldung}) => {
	return (
		<StepWrapper number="01">
			<>
				<p>Bitte wählen Sie Ihr Anliegen:</p>
				<hr />
				<div className="twodo__lightblue--container">
					<div className="twodo__radio-group">
						{clientOptions.map(option => {
							return <RadioGroup
								option={option}
								key={slugifyString(option)}
								selected={anmeldung.terminart === option}
								slug={slugifyString(option)}
								name="terminart"
								change={e => handleRadioChange(e)}/>
						})}

					</div>
				</div>

				<Button click={e => setAnmeldung({...anmeldung, step: 2})}>Übernehmen</Button>
			</>
		</StepWrapper>
	)
}

export default Step1