import React from 'react'

const RadioGroup = ({ option, change, selected, slug, name }) => {

	return (
		<div className='twodo__radio-element'>
			<input
				onChange={change}
				type="radio"
				id={slug}
				checked={selected}
				name={name}
				value={option} />
			<label htmlFor={slug}>{option}</label>
		</div>
	)
}

export default RadioGroup