import React, { useEffect, useState, useContext } from 'react'
import { AnmeldungContext } from '../Context/StateProvider'
import Button from '../Button/Button'
import DatePicker from 'react-datepicker'
import addDays from 'date-fns/addDays'
import getDay from 'date-fns/getDay'
import subHours from 'date-fns/subHours'
import addHours from 'date-fns/addHours'
import calculateBusinessDays from '../Functions/calculateBusinessDay'
import { renderTime, renderDate } from '../Functions/dateFunctions'
import 'react-datepicker/dist/react-datepicker.min.css'
import { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import axios from 'axios'
registerLocale('de', de)


const DateSelector = (props) => {


	const [anmeldung, setAnmeldung] = useContext(AnmeldungContext)
	const [belegt, setBelegt] = useState([])

	const [currentClient] = useState({
		id: '',
		open: anmeldung.open
	})

	useEffect(() => {
		let api = `https://api.twodo.online/termine/bookings/read.php?id=${anmeldung.clientid}`
		axios.get(api).then(res => {
			if (res.data.message !== "Keine Daten gefunden") {
				setBelegt(res.data)			
			}
		}).catch(err => { 
			console.log(err);
		})

		// eslint-disable-next-line
	}, [])



	const [showDate, setShowDate] = useState(true)

	const handleTerminChange = () => {
		setShowDate(!showDate)
		setAnmeldung({ ...anmeldung, step: 3, termin: '' })
	}

	const [time, setTime] = useState(new Date())

	const [date, setDate] = useState(() => {
		const diff = calculateBusinessDays(new Date(), addDays(new Date(), parseInt(anmeldung.blockedDays)))
		let newDate
		switch (diff) {
			case 1:
				newDate = addDays(new Date(),  anmeldung.blockedDays)
				break
			case 2:
				newDate = addDays(new Date(),  anmeldung.blockedDays)
				break
			case 3:
				newDate = addDays(new Date(),  anmeldung.blockedDays)
				break
			default:
				newDate = addDays(new Date(), anmeldung.blockedDays)
		}
		newDate.setHours(7)
		newDate.setMinutes(0)
		return newDate
	}

	)

	//Nur geöffnete Tage anzeigen lassen

	const setOpeningDays = () => {
		const days = []
		currentClient.open.map((d, i) => {
			if (d !== '') {
				return days.push(i + 1)
			}
			return days
		})
		return days
	}

	// Checkt ob es ein Wochentag ist und an diesem Tag geöffnet ist

	const isWeekday = (date) => {
		let showDay = false
		const day = getDay(date);
		const days = setOpeningDays()
		days.map(d => {
			if (day === d) showDay = true
			return showDay
		})
		return showDay;
	};

	const setTimeOnly = (dateTime) => {
		var date = new Date(dateTime.getTime());
		date.setDate(1);
		date.setMonth(0);
		date.setYear(2000);
		return date;
	}


	const isTimeAvailable = (curr, timeToCheck) => {
		curr.setDate(date.getDate())
		curr.setMonth(date.getMonth())
		curr.setYear(date.getFullYear())

		const currLow = subHours( curr, 1 )
		const currHigh = addHours( curr, 1 )
		return (currLow < timeToCheck && currHigh > timeToCheck);
	}


	// Prüfe, ob Zeitslot innerhalb der Öffnungszeiten ist

	const isWithinTimeWindow = (slot, d) => {

		
		// Gibt es Öffnungszeiten für Vormittag und Nachmittag
		if (slot.length > 1) {

			const times1 = slot[0].split('-') // 09:00-14:00
			const times2 = slot[1].split('-') // 15:00-18:00

			// Erster Slot

			const times1Start = times1[0] // 09:00
			const times1End = times1[1] // 14:00

			const times1StartHours = parseInt(times1Start.split(':')[0]) // 9
			const times1StartMinutes = parseInt(times1Start.split(':')[1]) // 0
			const times1EndHours = parseInt(times1End.split(':')[0]) // 14
			const times1EndMinutes = parseInt(times1End.split(':')[1]) // 0

			// Zweiter Slot

			const times2Start = times2[0] // 15:00
			const times2End = times2[1] // 18:00

			const times2StartHours = parseInt(times2Start.split(':')[0]) // 15
			const times2StartMinutes = parseInt(times2Start.split(':')[1]) // 0
			const times2EndHours = parseInt(times2End.split(':')[0]) // 18
			const times2EndMinutes = parseInt(times2End.split(':')[1]) // 0

			const start1Date = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times1StartHours, times1StartMinutes))
			const end1Date = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times1EndHours, times1EndMinutes))
			const start2Date = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times2StartHours, times2StartMinutes))
			const end2Date = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times2EndHours, times2EndMinutes))
			const normalizedDate = setTimeOnly(d)			

			const isInFirstSlot = normalizedDate >= start1Date && normalizedDate <= subHours(end1Date, 1)
			const isInSecondSlot = normalizedDate >= start2Date && normalizedDate <= subHours(end2Date, 1)

			if (isInFirstSlot || isInSecondSlot) { 
				return true
			}

			return false
		}
		// Es gibt keine Mittagspause
		else { 
			const times1 = slot[0].split('-') // 09:00-18:00

			const times1Start = times1[0] // 09:00
			const times1End = times1[1] // 18:00

			const times1StartHours = parseInt(times1Start.split(':')[0]) // 9
			const times1StartMinutes = parseInt(times1Start.split(':')[1]) // 0
			const times1EndHours = parseInt(times1End.split(':')[0]) // 18
			const times1EndMinutes = parseInt(times1End.split(':')[1]) // 0

			const startDate = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times1StartHours, times1StartMinutes))
			const endDate = setTimeOnly(new Date(date.getFullYear(), date.getMonth(), date.getDate(), times1EndHours, times1EndMinutes))
			const normalizedDate = setTimeOnly(d)


			return normalizedDate >= startDate && normalizedDate <= subHours(endDate, 1)
		}

	 }



	const filterTime = (time, chosenDate) => {
		let showTime = false
		const selectedDate = new Date(time);

		const chosenDayOpeningTimes = currentClient.open[date.getDay() - 1]
		const chosenDayOpeningArray = chosenDayOpeningTimes.split(' & ')

		// Öffnungszeiten checken
		showTime = isWithinTimeWindow(chosenDayOpeningArray, selectedDate)

		// Vorhandene Buchbungen prüfen
		belegt && belegt.map(b => { 
			return showTime = showTime ? !isTimeAvailable(time, new Date(b)) : false
		})

		return showTime;
	};



	const handleChange = d => {
		setDate(d)
		setAnmeldung({
			...anmeldung,
			step: anmeldung.step + 1,
			termin: d
		})
		setShowDate(false)
	}

	const handleTimeChange = d => {
		d.setDate(date.getDate())
		d.setMonth(date.getMonth())
		d.setYear(date.getFullYear())
		setTime(d)
		setAnmeldung({
			...anmeldung,
			step: anmeldung.step + 1,
			uhrzeit: d
		})
	}
	return (
		<>
			{
				anmeldung.clientid !== '' &&
				<div className="date__picker">
					{anmeldung.clientid !== '' && showDate ?
						<>
								<p>Bitte wählen Sie einen Tag:</p><br/>
								<div className="twodo__lightblue--container">
									<DatePicker
										locale={de}
										selected={date}
										onChange={handleChange}
										previousMonthButtonLabel="<"
										nextMonthButtonLabel=">"
										// ref={c => showPicker(c)}
										minDate={addDays(new Date(), parseInt(anmeldung.blockedDays))}
										filterDate={isWeekday}
										inline
									/>
								</div>
						</>
						:
						<>
							<p>Ihr gewählter Tag:</p>
							<strong>{renderDate(date)}</strong>
							{anmeldung.uhrzeit !== '' &&
								<>
									<p>{renderTime(anmeldung.uhrzeit)}</p>
									{anmeldung.step === 4 && <Button
										className="modal__button"
										click={e => setAnmeldung({ ...anmeldung, step: anmeldung.step + 1 })}
									>Termin übernehmen</Button>}
								</>
							}
							<br /><br /><p onClick={handleTerminChange} className="twodo__selector twodo__accent-color">Anderen Termin wählen</p>

							{/* <hr /> */}
							{anmeldung.step === 4 &&
								<><p style={{ marginTop: '40px', display: 'block' }}>Bitte wählen Sie eine Uhrzeit, Dauer des Termins ca. 60 Minuten: </p><br/>
									<div className="twodo__lightblue--container">
										<DatePicker
											locale={de}
											selected={time}
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={30}
											dateFormat="h:mm aa"
											onChange={handleTimeChange}
											// ref={c => showPicker(c)}
											filterTime={(time) => filterTime(time, date)}
											inline
										// includeTimes={showTimes()}
										/>
									</div>
								</>
							}
						</>
					}
				</div>
			}
		</>
	)
}

export default DateSelector