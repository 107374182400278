import React from 'react'

const StepWrapper = (props) => {
	return (
		<div className="twodo__step--wrapper">
			<div className="twodo__step--background"></div>
			<p className="twodo__countertext">{props.number}</p>
			{props.children}
		</div>
	)
}

export default StepWrapper