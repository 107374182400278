import { AnmeldungProvider } from './Components/Context/StateProvider'

import AppContent from './AppContent';




function App(props) {
	
	return (
		<AnmeldungProvider id={props.el.dataset.client} button={props.el.dataset.button}>
			<AppContent el={props.el} />
		</AnmeldungProvider>
	);
}

export default App;

