import { useState, useContext } from 'react'
import PrimaryButton from './Components/Button/PrimaryButton'
import Modal from './Components/Modal/Modal'
import ReactDOM from 'react-dom';
import GlobalStyle from './Components/Styles/GlobalStyles';
import { ClientContext } from './Components/Context/StateProvider'
import Vars from './Components/Styles/Vars';
import Terminabfrage from './Components/Terminabfrage/Terminabfrage';

const reactButtonElements = document.querySelectorAll('twodo__open-termine');

const AppContent = ({el}) => {

	
	const [client] = useContext(ClientContext)
	const [showModal, setShowModal] = useState(false)

	const buttonText = el.innerText !== '' ? el.innerText : el.dataset.button

	reactButtonElements.forEach(el => { 
		el.addEventListener('click', () => { 
			setShowModal(true)
		})
	})
	
	const twodoOpenModal = () => {
		setShowModal(true)
	}

	return (
		<div className="App">
			<GlobalStyle custom={ Vars(client[0]) }/>
			<PrimaryButton click={twodoOpenModal}>{buttonText}</PrimaryButton>
			{showModal &&
				<Portal>
					<Modal click={setShowModal} >
						<Terminabfrage/>
					</Modal>
				</Portal>}
		</div>
	)
}

export default AppContent

const Portal = (props) => {
	const portalRoot = document.body
	return ReactDOM.createPortal(
		props.children,
		portalRoot
	)
}
