import { createGlobalStyle } from 'styled-components'
import Check from '../../images/icn-check.svg'
import { lighten } from 'polished'


const GlobalStyle = createGlobalStyle`

		.twodo__accent-bg {
			background-color: ${(props) => props.custom.primary}!important;
		}
		.twodo__accent-color {
			color: ${(props) => props.custom.primary}!important;
		}
		.twodo__button-color {
			color: ${(props) => props.custom.buttonFont}!important;
		}
		.twodo__text-color {
			color: ${(props) => props.custom.textColor}!important;
		}
	#twodotermin, .twodo__open-termine, .twodo__termine-full {
		font-family: inherit;
		box-sizing: border-box!important;
		* {
			font-family: inherit;
			color: ${(props) => props.custom.textColor};
			box-sizing: border-box!important;
		}
		.twodo__main-button {
			background-color: ${props => props.custom.primary}; 
			color: ${props => props.custom.buttonFont}!important;
			display: inline-block;
			padding: 10px 20px;
			font-size: 16px;
			cursor: pointer;
			border-radius: 5px;
		}

		/* Modal */		
		
	}

	.twodo__modal--container {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		margin: 0;			
		background-color: rgba(0,0,0,0.7);
		z-index: 999999999999999999999999;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		font-family: inherit;
	}

	.twodo__modal--container, .twodo__termine-full {
		box-sizing: border-box;
		
		* {
			box-sizing: border-box!important;
			line-height: 1.4;
		}

		p {
			margin: 0;
			padding-top: 0;
			padding-bottom: 0;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}

		.twodo__modal--content{
			box-sizing: border-box;
			background-color: #fff;
			padding: 20px 20px 0;
			width: 100%;
			max-width: 600px;
			max-height: 100%;
			overflow-y: auto;
			font-family: inherit;
			&.twodo__modal--full {
				max-width: 100%;
			}
			.twodo__modal--close-button {
				margin-left: auto;
				font-family: inherit;
				text-align: right;
				font-size: 14px;
				color: #666;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				cursor: pointer;
				margin-bottom: 20px;
				
				svg {
					margin-left: 5px;
					font-size: 20px;
				}
			}
			hr {
					border: none;
					width: 100%;
					height: 2px;
					background-color: ${(props) => props.custom.primary}!important;
					margin: 10px 0;
				}

			/* Steps */
			.twodo__step--wrapper{
				width: 100%;
				margin: 20px 0 0;
				position: relative;
				text-align: center;
				display: block;
				&:first-of-type {
					margin-bottom: 20px;
				}
				> p {
					color: ${props => props.custom.textColor};
					padding: 0;
					//margin: 5px 0 15px;
				}
				hr {
					border: none;
					width: 100%;
					height: 2px;
					background-color: ${(props) => props.custom.primary}!important;
					margin: 10px 0;
				}
				* {
					font-size: 16px;
				}
				h4
					{
					font-weight: normal;
					margin-bottom: 15px;
				}
				> strong {
					margin-bottom: 10px;
					display: block;
				}
				&:before
					{
						content: "";
						position: absolute;
						left: 0;
						top: 20px;
						width: calc(50% - 40px);
						height: 2px;
						background: ${props => props.custom.primary};
					}
				&:after{
					content: "";
					position: absolute;
					right: 0;
					top: 20px;
					width: calc(50% - 40px);
					height: 2px;
					background: ${props => props.custom.primary};
				}
				.twodo__countertext
					{
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto 10px;
						padding: 0;
						color: ${props => props.custom.textColor};
						width: 40px;
						height: 40px;
						line-height: 1;
						font-size: 30px;
						position: relative;
				}
			}
			.twodo__lightblue--container {
				position: relative;
				padding: 20px;

				&::before{
					content: "";
					pointer-events: none;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;	
					background: linear-gradient(to right, transparent, ${props => props.custom.primary}, transparent );
					opacity: 0.2
				}
			}
			.twodo__client-select {
				display: flex;
				align-items: center;
				justify-content: space-between;
				text-align: left;
				.twodo__client-content {
					padding-right: 20px;
					margin: 10px 0;
					p {
						padding: 0;
						margin: 0;
						line-height: 1.4;
						display: block;
					}
				}
				.twodo__client-cta {
					p {
						padding: 0;
						margin: 0;
						text-align: right;
						font-weight: bold;
						color: ${props => props.custom.primary}!important;
						cursor: pointer!important;
					}
				}
			}
			.twodo__selected-client {
				p {
					padding: 0!important;
					margin: 0!important;
					line-height: 1.6;
				}
			}
			.twodo__selector {
				font-weight: bold;
				cursor: pointer;
				font-size: 14px;
			}
	
			/* Radios */
	
			.twodo__radio-element {
				padding: 10px;
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				position: relative;
			
				label {
					display: block;
					font-weight: bold;
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					&:after {
						content: "";
						display: block;
						position: relative;
						width: 20px;
						min-width: 20px;
						height: 20px;
						border: 2px solid ${props => props.custom.primary};
						background: white;
					}
				}
				input {
					display: none;
					&:checked ~ label::after {					
						background-color: ${props => props.custom.primary};
						background-image: url(${Check});
						background-size: 15px;
						background-position: center;
						background-repeat: no-repeat;
					}
				}
	
				
			}
			.main__button, .twodo__main-button {
					all: unset;
					appearance: none;
					background-color: ${props => props.custom.primary}!important;
					color: ${props => props.custom.buttonFont}!important;
					padding: 10px 20px;
					display: inline-block;
					margin-top: 20px;
					cursor: pointer!important;
					transition: all 300ms ease;
					font-weight: bold;
					border: 2px solid ${props => props.custom.primary}!important;
					&:hover {
						/* background-color: ${props => lighten(0.15, props.custom.primary)}!important; */
						background-color: white!important;
						border: 2px solid ${props => props.custom.primary}!important;
						color: ${props => props.custom.primary}!important;
						
					}
				}
			.twodo__terminabfrage {
				text-align: center;
				.twodo__icon {
					width: 98px!important;
					height: 40px!important;
					margin: 0 auto;
					display: block;
				}
				.twodo__headline {
					font-size: 30px;
					text-transform: uppercase;
					display: block;
					margin-top: 30px;
					margin-bottom: 0;
					padding: 0;
				}
				
			}
	
		}

		/**====================================== */
		/* Datepicker */
		/**====================================== */
		.react-datepicker-wrapper {
			display: none!important;
		}

		.react-datepicker__tab-loop{
			.react-datepicker__triangle
				{
					display: none!important;
				}
			}
		.react-datepicker {
			width: 100%;
			border: none!important;
			margin-top: 20px;
			background-color: transparent;
			.react-datepicker__navigation {
				align-items: center;
				background: none;
				display: flex;
				justify-content: center;
				text-align: center;
				cursor: pointer;
				position: absolute;
				top: 2px;
				padding: 0;
				border: none;
				z-index: 1;
				height: 32px;
				width: 32px;
				text-indent: -999em;
				overflow: hidden;
			}
			.react-datepicker__navigation--next {
				align-items: center;
				background: none;
				display: flex;
				justify-content: center;
				text-align: center;
				cursor: pointer;
				position: absolute;
				top: 2px;
				padding: 0;
				border: none;
				z-index: 1;
				height: 32px;
				width: 32px;
				text-indent: -999em;
				overflow: hidden;
			}
			.react-datepicker__month-container,
			.react-datepicker__time-container {
				width: 100%;
				.react-datepicker__header
					{
						background: none;
						border: none!important;
					.react-datepicker__current-month,
					.react-datepicker-time__header {
						font-size: 16px;
						font-weight: bold;
						color: ${props => props.custom.primary};
					}
					.react-datepicker__day-names {
							display: none!important;
						}
					}
				.react-datepicker__month {
					
					.react-datepicker__week {
						width: 100%;
						max-width: 400px;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						.react-datepicker__day {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 30px;
							height: 30px;
							min-width: 30px;
							min-height: 30px;
							@media (min-width: 640px){
								width: 40px;
								height: 40px;
								min-width: 40px;
								min-height: 40px;
							}
						
							border-radius: 50%;
							&.react-datepicker__day--selected,
							&.react-datepicker__day--keyboard-selected {
								background: ${props => props.custom.primary};
								border-radius: 0;

							}
							&:hover {
								background: ${props =>lighten(0.40, props.custom.primary)};
								border-radius: 0;
							}
						}
					}
				}
			}
			.react-datepicker__time-list-item--disabled {
				display: none!important;
			}
			.react-datepicker__time-container {
				width: 100%;
				.react-datepicker__time {
					background: none;
					.react-datepicker__time-box {
						width: 100%;
						.react-datepicker__time-list {
							padding: 20px 0;
							width: 100%;
							max-width: 300px;
							margin: 0 auto;
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;
							height: auto;
							overflow: hidden;
							li
								{
									flex: 1 0 20%;
									max-width: calc(25% - 10px);
									margin: 5px;
									padding: 10px;
									display: flex;
									align-items: center;
									justify-content: center;
									border-radius: 10px;
									&:hover {
										background: ${props =>lighten(0.40, props.custom.primary)};
										border-radius: 0;
									}
									&.react-datepicker__time-list-item--selected {
										background: ${props => props.custom.primary};
										border-radius: 0;
									}
									}
						}
					}
				}

				.react-datepicker__header--time--only {
					display: none;

				}
			}


		}	
		/**====================================== */
		/* Datepicker Ende */
		/**====================================== */

		/**====================================== */
		/* Formular */
		/**====================================== */

		.twodo__termin-form {
			position: relative;
			width: 100%;
			max-width: 450px;
			margin: 0 auto;
			* {
				font-size: 16px;
			}
			> .lightblue__container {
				padding: 40px 0;
				border-bottom: 1px solid #000;
			}
			.twodo__input-group {
				flex-wrap: wrap;
				align-items: center;
				margin-bottom: 20px;
				&.align-start {
					align-items: flex-start;
				}
		
				@media (min-width: 340px){
					display: flex;
				}
		
				label{
					margin-right: 10px;
					text-align: left;
					width: 100px;
				}
				input:not([type=checkbox]), textarea {
					background-color: #fff;
					border: 2px solid ${props => props.custom.primary};
					flex: 1 0 auto;
					padding: 5px 10px;
					border-radius: 0!important;
					&:focus {
						border-color: ${props => lighten(0.3, props.custom.primary)};
						outline: none;
					}

				}
				input[type=checkbox]{
					margin-right: 10px;
					margin-top: 5px;

				}
				.twodo__error {
					max-width: calc(80% - 20px);
					margin-left: auto;
					flex: 1 0 100%;
					background-color: #fff;
					padding: 5px 10px;
					margin-top: 10px;
					border-top: 2px solid orangered;
					border-bottom: 2px solid orangered;
					color: orangered;
					margin-bottom: 0;
				}

				&.checkbox__group {
					align-items: flex-start;
					label{
						flex: 1 0 auto;
						width: 80%;
						a{
							text-decoration: none;
							color: ${props => props.custom.primary};
							display: inline-block;
							margin: 0 5px;
						}
						}
				}
		
				.twodo__dropdown {
					flex: 1 0 auto;
		
					> div {
						border: 2px solid${props => props.custom.primary};
						text-align: left;
						border-radius: 0;
						border-width: 2px;
					}
					*{
						outline: none!important;
						color: black;
					}
					div[class*=IndicatorsContainer] {
						>span {
							display: none;
						}
						background:${props => props.custom.primary};
						svg {
							fill: ${props => props.custom.buttonFont}!important
						}

					}

				}
			}
		}


		/**====================================== */
		/* Formular Ende */
		/**====================================== */

		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view--down-arrow,
		.react-datepicker__navigation-icon:before,
		.react-datepicker__year-read-view--down-arrow {
			border-color: #ccc;
			border-style: solid;
			border-width: 3px 3px 0 0;
			content: "";
			display: block;
			height: 9px;
			position: absolute;
			top: 6px;
			width: 9px
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
			margin-left: -4px;
			position: absolute;
			width: 0
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after,
		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before,
		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:after,
		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
			border: 8px solid transparent;
			box-sizing: content-box;
			content: "";
			height: 0;
			left: -8px;
			position: absolute;
			width: 1px;
			z-index: -1
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before,
		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
			border-bottom-color: #aeaeae
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
			margin-top: -8px;
			top: 0
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after,
		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
			border-bottom-color: #f0f0f0;
			border-top: none
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:after {
			top: 0
		}

		.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
			border-bottom-color: #aeaeae;
			top: -1px
		}

		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
			bottom: 0;
			margin-bottom: -8px
		}

		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:after,
		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
			border-bottom: none;
			border-top-color: #fff
		}

		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:after {
			bottom: 0
		}

		.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
			border-top-color: #aeaeae;
			bottom: -1px
		}

		.react-datepicker-wrapper {
			border: 0;
			display: inline-block;
			padding: 0;
			width: 100%
		}

		.react-datepicker {
			background-color: #fff;
			border: 1px solid #aeaeae;
			border-radius: .3rem;
			color: #000;
			display: inline-block;
			font-family: Helvetica Neue, helvetica, arial, sans-serif;
			font-size: .8rem;
			position: relative
		}

		.react-datepicker--time-only .react-datepicker__triangle {
			left: 35px
		}

		.react-datepicker--time-only .react-datepicker__time-container {
			border-left: 0
		}

		.react-datepicker--time-only .react-datepicker__time,
		.react-datepicker--time-only .react-datepicker__time-box {
			border-bottom-left-radius: .3rem;
			border-bottom-right-radius: .3rem
		}

		.react-datepicker__triangle {
			left: 50px;
			position: absolute
		}

		.react-datepicker-popper {
			z-index: 1
		}

		.react-datepicker-popper[data-placement^=bottom] {
			padding-top: 10px
		}

		.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
		.react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
			left: auto;
			right: 50px
		}

		.react-datepicker-popper[data-placement^=top] {
			padding-bottom: 10px
		}

		.react-datepicker-popper[data-placement^=right] {
			padding-left: 8px
		}

		.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
			left: auto;
			right: 42px
		}

		.react-datepicker-popper[data-placement^=left] {
			padding-right: 8px
		}

		.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
			left: 42px;
			right: auto
		}

		.react-datepicker__header {
			background-color: #f0f0f0;
			border-bottom: 1px solid #aeaeae;
			border-top-left-radius: .3rem;
			padding: 8px 0;
			position: relative;
			text-align: center
		}

		.react-datepicker__header--time {
			padding-bottom: 8px;
			padding-left: 5px;
			padding-right: 5px
		}

		.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
			border-top-left-radius: 0
		}

		.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
			border-top-right-radius: .3rem
		}

		.react-datepicker__month-dropdown-container--scroll,
		.react-datepicker__month-dropdown-container--select,
		.react-datepicker__month-year-dropdown-container--scroll,
		.react-datepicker__month-year-dropdown-container--select,
		.react-datepicker__year-dropdown-container--scroll,
		.react-datepicker__year-dropdown-container--select {
			display: inline-block;
			margin: 0 2px
		}

		.react-datepicker-time__header,
		.react-datepicker-year-header,
		.react-datepicker__current-month {
			color: #000;
			font-size: .944rem;
			font-weight: 700;
			margin-top: 0
		}

		.react-datepicker-time__header {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap
		}

		.react-datepicker__navigation {
			align-items: center;
			background: none;
			border: none;
			cursor: pointer;
			display: flex;
			height: 32px;
			justify-content: center;
			overflow: hidden;
			padding: 0;
			position: absolute;
			text-align: center;
			text-indent: -999em;
			top: 2px;
			width: 32px;
			z-index: 1
		}

		.react-datepicker__navigation--previous {
			left: 2px
		}

		.react-datepicker__navigation--next {
			right: 2px
		}

		.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
			right: 85px
		}

		.react-datepicker__navigation--years {
			display: block;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			top: 0
		}

		.react-datepicker__navigation--years-previous {
			top: 4px
		}

		.react-datepicker__navigation--years-upcoming {
			top: -4px
		}

		.react-datepicker__navigation:hover :before {
			border-color: #a6a6a6
		}

		.react-datepicker__navigation-icon {
			font-size: 20px;
			position: relative;
			top: -1px;
			width: 0
		}

		.react-datepicker__navigation-icon--next {
			left: -2px
		}

		.react-datepicker__navigation-icon--next:before {
			left: -7px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg)
		}

		.react-datepicker__navigation-icon--previous {
			right: -2px
		}

		.react-datepicker__navigation-icon--previous:before {
			right: -7px;
			-webkit-transform: rotate(225deg);
			transform: rotate(225deg)
		}

		.react-datepicker__month-container {
			float: left
		}

		.react-datepicker__year {
			margin: .4rem;
			text-align: center
		}

		.react-datepicker__year-wrapper {
			display: flex;
			flex-wrap: wrap;
			max-width: 180px
		}

		.react-datepicker__year .react-datepicker__year-text {
			display: inline-block;
			margin: 2px;
			width: 4rem
		}

		.react-datepicker__month {
			margin: .4rem;
			text-align: center
		}

		.react-datepicker__month .react-datepicker__month-text,
		.react-datepicker__month .react-datepicker__quarter-text {
			display: inline-block;
			margin: 2px;
			width: 4rem
		}

		.react-datepicker__input-time-container {
			clear: both;
			float: left;
			margin: 5px 0 10px 15px;
			text-align: left;
			width: 100%
		}

		.react-datepicker__input-time-container .react-datepicker-time__caption,
		.react-datepicker__input-time-container .react-datepicker-time__input-container {
			display: inline-block
		}

		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
			display: inline-block;
			margin-left: 10px
		}

		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
			width: auto
		}

		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0
		}

		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
			-moz-appearance: textfield
		}

		.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
			display: inline-block;
			margin-left: 5px
		}

		.react-datepicker__time-container {
			border-left: 1px solid #aeaeae;
			float: right;
			width: 85px
		}

		.react-datepicker__time-container--with-today-button {
			border: 1px solid #aeaeae;
			border-radius: .3rem;
			display: inline;
			position: absolute;
			right: -72px;
			top: 0
		}

		.react-datepicker__time-container .react-datepicker__time {
			background: #fff;
			border-bottom-right-radius: .3rem;
			position: relative
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
			border-bottom-right-radius: .3rem;
			margin: 0 auto;
			overflow-x: hidden;
			text-align: center;
			width: 85px
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
			box-sizing: content-box;
			height: calc(195px + .85rem);
			list-style: none;
			margin: 0;
			overflow-y: scroll;
			padding-left: 0;
			padding-right: 0;
			width: 100%
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
			height: 30px;
			padding: 5px 10px;
			white-space: nowrap
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
			background-color: #f0f0f0;
			cursor: pointer
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
			background-color: #216ba5;
			color: #fff;
			font-weight: 700
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
			background-color: #216ba5
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
			color: #ccc
		}

		.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
			background-color: transparent;
			cursor: default
		}

		.react-datepicker__week-number {
			color: #ccc;
			display: inline-block;
			line-height: 1.7rem;
			margin: .166rem;
			text-align: center;
			width: 1.7rem
		}

		.react-datepicker__week-number.react-datepicker__week-number--clickable {
			cursor: pointer
		}

		.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
			background-color: #f0f0f0;
			border-radius: .3rem
		}

		.react-datepicker__day-names,
		.react-datepicker__week {
			white-space: nowrap
		}

		.react-datepicker__day-names {
			margin-bottom: -8px
		}

		.react-datepicker__day,
		.react-datepicker__day-name,
		.react-datepicker__time-name {
			color: #000;
			display: inline-block;
			line-height: 1.7rem;
			margin: .166rem;
			text-align: center;
			width: 1.7rem
		}

		.react-datepicker__month--in-range,
		.react-datepicker__month--in-selecting-range,
		.react-datepicker__month--selected,
		.react-datepicker__quarter--in-range,
		.react-datepicker__quarter--in-selecting-range,
		.react-datepicker__quarter--selected {
			background-color: #216ba5;
			border-radius: .3rem;
			color: #fff
		}

		.react-datepicker__month--in-range:hover,
		.react-datepicker__month--in-selecting-range:hover,
		.react-datepicker__month--selected:hover,
		.react-datepicker__quarter--in-range:hover,
		.react-datepicker__quarter--in-selecting-range:hover,
		.react-datepicker__quarter--selected:hover {
			background-color: #1d5d90
		}

		.react-datepicker__month--disabled,
		.react-datepicker__quarter--disabled {
			color: #ccc;
			pointer-events: none
		}

		.react-datepicker__month--disabled:hover,
		.react-datepicker__quarter--disabled:hover {
			background-color: transparent;
			cursor: default
		}

		.react-datepicker__day,
		.react-datepicker__month-text,
		.react-datepicker__quarter-text,
		.react-datepicker__year-text {
			cursor: pointer
		}

		.react-datepicker__day:hover,
		.react-datepicker__month-text:hover,
		.react-datepicker__quarter-text:hover,
		.react-datepicker__year-text:hover {
			background-color: #f0f0f0;
			border-radius: .3rem
		}

		.react-datepicker__day--today,
		.react-datepicker__month-text--today,
		.react-datepicker__quarter-text--today,
		.react-datepicker__year-text--today {
			font-weight: 700
		}

		.react-datepicker__day--highlighted,
		.react-datepicker__month-text--highlighted,
		.react-datepicker__quarter-text--highlighted,
		.react-datepicker__year-text--highlighted {
			background-color: #3dcc4a;
			border-radius: .3rem;
			color: #fff
		}

		.react-datepicker__day--highlighted:hover,
		.react-datepicker__month-text--highlighted:hover,
		.react-datepicker__quarter-text--highlighted:hover,
		.react-datepicker__year-text--highlighted:hover {
			background-color: #32be3f
		}

		.react-datepicker__day--highlighted-custom-1,
		.react-datepicker__month-text--highlighted-custom-1,
		.react-datepicker__quarter-text--highlighted-custom-1,
		.react-datepicker__year-text--highlighted-custom-1 {
			color: #f0f
		}

		.react-datepicker__day--highlighted-custom-2,
		.react-datepicker__month-text--highlighted-custom-2,
		.react-datepicker__quarter-text--highlighted-custom-2,
		.react-datepicker__year-text--highlighted-custom-2 {
			color: green
		}

		.react-datepicker__day--in-range,
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__day--selected,
		.react-datepicker__month-text--in-range,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__month-text--selected,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__quarter-text--selected,
		.react-datepicker__year-text--in-range,
		.react-datepicker__year-text--in-selecting-range,
		.react-datepicker__year-text--selected {
			background-color: #216ba5;
			border-radius: .3rem;
			color: #fff
		}

		.react-datepicker__day--in-range:hover,
		.react-datepicker__day--in-selecting-range:hover,
		.react-datepicker__day--selected:hover,
		.react-datepicker__month-text--in-range:hover,
		.react-datepicker__month-text--in-selecting-range:hover,
		.react-datepicker__month-text--selected:hover,
		.react-datepicker__quarter-text--in-range:hover,
		.react-datepicker__quarter-text--in-selecting-range:hover,
		.react-datepicker__quarter-text--selected:hover,
		.react-datepicker__year-text--in-range:hover,
		.react-datepicker__year-text--in-selecting-range:hover,
		.react-datepicker__year-text--selected:hover {
			background-color: #1d5d90
		}

		.react-datepicker__day--keyboard-selected,
		.react-datepicker__month-text--keyboard-selected,
		.react-datepicker__quarter-text--keyboard-selected,
		.react-datepicker__year-text--keyboard-selected {
			background-color: #2579ba;
			border-radius: .3rem;
			color: #fff
		}

		.react-datepicker__day--keyboard-selected:hover,
		.react-datepicker__month-text--keyboard-selected:hover,
		.react-datepicker__quarter-text--keyboard-selected:hover,
		.react-datepicker__year-text--keyboard-selected:hover {
			background-color: #1d5d90
		}

		.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
		.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
		.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
		.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
			background-color: rgba(33, 107, 165, .5)
		}

		.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
		.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
		.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
		.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
			background-color: #f0f0f0;
			color: #000
		}

		.react-datepicker__day--disabled,
		.react-datepicker__month-text--disabled,
		.react-datepicker__quarter-text--disabled,
		.react-datepicker__year-text--disabled {
			color: #ccc;
			cursor: default
		}

		.react-datepicker__day--disabled:hover,
		.react-datepicker__month-text--disabled:hover,
		.react-datepicker__quarter-text--disabled:hover,
		.react-datepicker__year-text--disabled:hover {
			background-color: transparent
		}

		.react-datepicker__month-text.react-datepicker__month--in-range:hover,
		.react-datepicker__month-text.react-datepicker__month--selected:hover,
		.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
		.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
		.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
		.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
		.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover,
		.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover {
			background-color: #216ba5
		}

		.react-datepicker__month-text:hover,
		.react-datepicker__quarter-text:hover {
			background-color: #f0f0f0
		}

		.react-datepicker__input-container {
			display: inline-block;
			position: relative;
			width: 100%
		}

		.react-datepicker__month-read-view,
		.react-datepicker__month-year-read-view,
		.react-datepicker__year-read-view {
			border: 1px solid transparent;
			border-radius: .3rem;
			position: relative
		}

		.react-datepicker__month-read-view:hover,
		.react-datepicker__month-year-read-view:hover,
		.react-datepicker__year-read-view:hover {
			cursor: pointer
		}

		.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
		.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
		.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
		.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
			border-top-color: #b3b3b3
		}

		.react-datepicker__month-read-view--down-arrow,
		.react-datepicker__month-year-read-view--down-arrow,
		.react-datepicker__year-read-view--down-arrow {
			right: -16px;
			top: 0;
			-webkit-transform: rotate(135deg);
			transform: rotate(135deg)
		}

		.react-datepicker__month-dropdown,
		.react-datepicker__month-year-dropdown,
		.react-datepicker__year-dropdown {
			background-color: #f0f0f0;
			border: 1px solid #aeaeae;
			border-radius: .3rem;
			left: 25%;
			position: absolute;
			text-align: center;
			top: 30px;
			width: 50%;
			z-index: 1
		}

		.react-datepicker__month-dropdown:hover,
		.react-datepicker__month-year-dropdown:hover,
		.react-datepicker__year-dropdown:hover {
			cursor: pointer
		}

		.react-datepicker__month-dropdown--scrollable,
		.react-datepicker__month-year-dropdown--scrollable,
		.react-datepicker__year-dropdown--scrollable {
			height: 150px;
			overflow-y: scroll
		}

		.react-datepicker__month-option,
		.react-datepicker__month-year-option,
		.react-datepicker__year-option {
			display: block;
			line-height: 20px;
			margin-left: auto;
			margin-right: auto;
			width: 100%
		}

		.react-datepicker__month-option:first-of-type,
		.react-datepicker__month-year-option:first-of-type,
		.react-datepicker__year-option:first-of-type {
			border-top-left-radius: .3rem;
			border-top-right-radius: .3rem
		}

		.react-datepicker__month-option:last-of-type,
		.react-datepicker__month-year-option:last-of-type,
		.react-datepicker__year-option:last-of-type {
			border-bottom-left-radius: .3rem;
			border-bottom-right-radius: .3rem;
			-webkit-user-select: none;
			-ms-user-select: none;
			user-select: none
		}

		.react-datepicker__month-option:hover,
		.react-datepicker__month-year-option:hover,
		.react-datepicker__year-option:hover {
			background-color: #ccc
		}

		.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
		.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming,
		.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
			border-bottom-color: #b3b3b3
		}

		.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
		.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous,
		.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
			border-top-color: #b3b3b3
		}

		.react-datepicker__month-option--selected,
		.react-datepicker__month-year-option--selected,
		.react-datepicker__year-option--selected {
			left: 15px;
			position: absolute
		}

		.react-datepicker__close-icon {
			background-color: transparent;
			border: 0;
			cursor: pointer;
			display: table-cell;
			height: 100%;
			outline: 0;
			padding: 0 6px 0 0;
			position: absolute;
			right: 0;
			top: 0;
			vertical-align: middle
		}

		.react-datepicker__close-icon:after {
			background-color: #216ba5;
			border-radius: 50%;
			color: #fff;
			content: "×";
			cursor: pointer;
			display: table-cell;
			font-size: 12px;
			height: 16px;
			line-height: 1;
			padding: 2px;
			text-align: center;
			vertical-align: middle;
			width: 16px
		}

		.react-datepicker__today-button {
			background: #f0f0f0;
			border-top: 1px solid #aeaeae;
			clear: left;
			cursor: pointer;
			font-weight: 700;
			padding: 5px 0;
			text-align: center
		}

		.react-datepicker__portal {
			align-items: center;
			background-color: rgba(0, 0, 0, .8);
			display: flex;
			height: 100vh;
			justify-content: center;
			left: 0;
			position: fixed;
			top: 0;
			width: 100vw;
			z-index: 2147483647
		}

		.react-datepicker__portal .react-datepicker__day,
		.react-datepicker__portal .react-datepicker__day-name,
		.react-datepicker__portal .react-datepicker__time-name {
			line-height: 3rem;
			width: 3rem
		}

		@media(max-height:550px),
		(max-width:400px) {

			.react-datepicker__portal .react-datepicker__day,
			.react-datepicker__portal .react-datepicker__day-name,
			.react-datepicker__portal .react-datepicker__time-name {
				line-height: 2rem;
				width: 2rem
			}
		}

		.react-datepicker__portal .react-datepicker-time__header,
		.react-datepicker__portal .react-datepicker__current-month {
			font-size: 1.44rem
		}


	}
	.twodo__termine-full {
		p {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
		.App {
			.twodo__terminabfrage {
				.twodo__step--wrapper {
					.twodo__lightblue--container {
						.twodo__radio-group {
							width: 100%;
							max-width: 800px;
							margin: 0 auto;
							@media (min-width: 768px) {
								display: grid;
								grid-template-columns: 1fr 1fr;
								grid-template-rows: auto;
								grid-gap: 10px;
							}
						}
					}
					.twodo__termin-form {
						max-width: 600px;
					}
					.twodo__error {
						max-width: calc(100%);
						margin-left: auto;
						flex: 1 0 100%;
						background-color: #fff;
						padding: 5px 10px;
						margin-top: 10px;
						border-top: 2px solid orangered;
						border-bottom: 2px solid orangered;
						color: orangered;
						margin-bottom: 0;
					}
				}

			}
		}
	}

	`

export default GlobalStyle