import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import Select from 'react-select'
import { AnmeldungContext, ClientContext } from '../Context/StateProvider';
import { renderDatabaseDate } from '../Functions/dateFunctions'
import axios from 'axios';


const TerminFormular = () => {

	const [anmeldung, setAnmeldung] = useContext(AnmeldungContext)
	const [clients] = useContext(ClientContext)



	const [anrede, setAnrede] = useState('')

	const style = {
		control: base => ({
			...base,
			// border: 0,
			boxShadow: 'none'
		})
	};
	return (
		<div>
			<Formik
				initialValues={{
					email: '',
					anrede: '',
					vorname: '',
					nachname: '',
					consent: '',
					telefon: '',
					comment: ''
				}}
				validate={values => {
					const errors = {};
					if (!anrede) {
						errors.anrede = 'Bitte Anrede wählen'
					} else if (!values.vorname) {
						errors.vorname = 'Bitte Vornamen angeben'
					} else if (!values.nachname) {
						errors.nachname = 'Bitte Nachnamen angeben'
					} else if (!values.email) {
						errors.email = 'Pflichtfeld';
					} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						errors.email = 'Ungültige E-Mail Adresse';
					} else if (!values.consent) {
						errors.consent = 'Bitte stimmen Sie den Datenschutzbestimmungen zu'
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					const data = {
						id: anmeldung.clientid,
						optiker: anmeldung.geschaeft,
						strasse: anmeldung.strasse,
						filiale: anmeldung.filiale,
						emailoptiker: anmeldung.email,
						plz: anmeldung.plz,
						ort: anmeldung.ort,
						anrede: anrede,
						vorname: values.vorname,
						nachname: values.nachname,
						email: values.email,
						telefon: values.telefon,
						comment: values.comment,
						token: anmeldung.token,
						termin: renderDatabaseDate(anmeldung.uhrzeit),
						terminart: anmeldung.terminart,
						url: window.location.href
					}
					setTimeout(() => {

						const formData = new FormData()
						let fData = JSON.stringify(data)
						formData.append('terminanfrage', fData)

						const submitUrl = "https://api.twodo.online/termine/bookings/post.php"

						axios.post(submitUrl, formData).then(res => {
							setAnmeldung({
								...anmeldung,
								anrede: anrede,
								vorname: data.vorname,
								nachname: data.nachname,
								email: data.email,
								telefon: data.telefon,
								comment: data.comment,
								step: anmeldung.step + 1
							})
						}).catch(e => {
							console.log('Fehler', e);
						})
						setSubmitting(false);
					}, 400);
				}}>

				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting
				}) => (

					<form className="twodo__termin-form" onSubmit={handleSubmit} noValidate>

						<div className="lightblue__container">
							<div className='twodo__input-group'>
								<label>Anrede*</label>
								{/* <select name="anrede" id="anrede" onChange={handleChange}>
								<option value="*">Bitte auswählen</option>
								<option value="Frau">Frau</option>
								<option value="Herr">Herr</option>
							</select> */}
								<Select
									className="twodo__dropdown"
									id="twodo__anrede-termin"
									onChange={e => {
										setAnrede(e.value)
									}}
									styles={style}
									options={[
										{
											label: 'Frau',
											value: 'Frau'
										},
										{
											label: 'Herr',
											value: 'Herr'
										},
										{
											label: clients[0].genderneutral,
											value: clients[0].genderneutral
										}
									]}
									placeholder={"Bitte auswählen"} /> {errors.anrede && <p className='twodo__error'>{errors.anrede}</p>}
							</div>
							<div className='twodo__input-group'>
								<label>Vorname*</label>
								<input
									type="text"
									name="vorname"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.vorname} /> {errors.vorname && <p className='twodo__error'>{errors.vorname}</p>}
							</div>

							<div className='twodo__input-group'>
								<label>Nachname*</label>
								<input
									type="text"
									name="nachname"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.nachname} /> {errors.nachname && <p className='twodo__error'>{errors.nachname}</p>}
							</div>

							<div className='twodo__input-group'>
								<label>E-Mail*</label>
								<input
									type="email"
									name="email"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.email} /> {errors.email && touched.email && <p className='twodo__error'>{errors.email}</p>}
							</div>

							<div className='twodo__input-group'>
								<label>Telefon</label>
								<input
									type="telefon"
									name="telefon"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.telefon} />
							</div>
				
							<div className='twodo__input-group align-start'>
								<label>Anmerkungen</label>
								<textarea
									name="comment"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.comment} />
							</div>

							<div className='twodo__input-group checkbox__group'>
								<input
									type="checkbox"
									name="consent"
									id="consent"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.consent} />
								<label htmlFor='consent'>Ich habe die
									<a
										href={clients[0].datenschutz}
										target="_blank"
										rel="noreferrer">Datenschutzerklärung</a>
									zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur
									Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden.*
								</label>
								{errors.consent && <p className='twodo__error'>{errors.consent}</p>}
							</div>
						</div>
						<button className='main__button' type="submit" disabled={isSubmitting}>Anfrage absenden</button>
					</form>

				)}

			</Formik>
		</div>
	)
}

export default TerminFormular