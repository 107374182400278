import { useContext } from 'react'
import { CgCloseO } from 'react-icons/cg'
import { AnmeldungContext } from '../Context/StateProvider'



const Modal = (props) => {

	const [anmeldung, setAnmeldung] = useContext(AnmeldungContext)

	const handleClick = () => { 
		setAnmeldung({
			...anmeldung,
			step: 1
		})
		props.click(false)
	}

	window.addEventListener('keyup', e => e.key === 'Escape' && handleClick())


	return (
		<div className="twodo__modal--container">
			<div className="twodo__modal--content">
				<div onClick={handleClick} className="twodo__modal--close-button">Schliessen <CgCloseO /></div>
				{props.children}
			</div>
		</div>
	)
}

export default Modal