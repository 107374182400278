import React, { useEffect } from 'react'
import BrillenIcon from '../../images/icon-brille.svg'
import { useContext } from 'react'
import { ClientContext, AnmeldungContext } from '../Context/StateProvider'
import { renderTime, renderDate } from '../Functions/dateFunctions'
import Step1 from '../Steps/Step1'
import TerminSelection from './TerminSelection'
import Step2 from '../Steps/Step2'
import Step3 from '../Steps/Step3'
import Step4 from '../Steps/Step4'



const Terminabfrage = () => {

	const [clients] = useContext(ClientContext)
	const [anmeldung, setAnmeldung] = useContext(AnmeldungContext)

	useEffect(() => {
		setAnmeldung({
			...anmeldung,
			terminart: clientOptions[0]
		})
		// eslint-disable-next-line
	}, [clients])



	const splitClientOptions = (str) => str.split(' | ')
	const slugifyString = (str) => str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
	const clientOptions = clients[0] ? splitClientOptions(clients[0].options) : []


	const handleRadioChange = (e) => {
		setAnmeldung({ ...anmeldung, terminart: e.target.value })
	}

	return (
		<div className="twodo__terminabfrage">
			<img className="twodo__icon" src={BrillenIcon} alt="Icon" />

			{anmeldung.step <= 5 ?
				<><p className="twodo__headline">Ihr persönlicher Termin</p>
					{anmeldung.step > 1 && <TerminSelection step={anmeldung.step} anmeldung={anmeldung} />}

					{anmeldung.step === 1 &&
						<Step1
							clientOptions={clientOptions}
							slugifyString={slugifyString}
							handleRadioChange={handleRadioChange}
							anmeldung={anmeldung}
							setAnmeldung={setAnmeldung} />
					}

					{anmeldung.step >= 2 &&
						<Step2
							anmeldung={anmeldung}
							setAnmeldung={setAnmeldung}
							clients={clients}
						/>

					}
					{anmeldung.step >= 3 &&
						<Step3
							anmeldung={anmeldung}
							setAnmeldung={setAnmeldung}
							clients={clients}
						/>
						// <StepWrapper number="03">
						// 	{/* <DateSelector /> */}
						// </StepWrapper>
					}
					{anmeldung.step === 5 &&
						<Step4/>
					}
				</>
				:
				<>
					{
						anmeldung.step === 'error' ?
							<><p className="twodo__headline">Ihre Anfrage konnte nicht gesendet werden.</p>
								<hr />
								<p>Bitte versuchen Sie es später erneut.</p><br />
								<br />
								<br /></>
							:
							<><p className="twodo__headline">Vielen Dank!</p><br/>
								<hr /><br/>
								<p>Ihre Anfrage wurde mit folgendem Inhalt versendet an:</p><br />
								<p><strong>{anmeldung.geschaeft}</strong></p>
								<p>{anmeldung.strasse}</p>
								<p>{anmeldung.plz} {anmeldung.ort}</p><br />
								<p>Termin:</p>
								<p><strong>{renderDate(anmeldung.uhrzeit)}</strong></p>
								<p>{renderTime(anmeldung.uhrzeit)}</p><br />
								<p>Ihre Daten:</p>
								<p><strong>{anmeldung.anrede === 'Herr' || anmeldung.anrede === 'Frau' ? anmeldung.anrede + " " : '' }{anmeldung.vorname} {anmeldung.nachname}</strong></p>
								<p>{anmeldung.email}</p>
								{anmeldung.telefon !== "" &&
									<p>{anmeldung.telefon}</p>
								}
								<br /><hr/><br/></>
					}


				</>
			}

		</div>
	)
}

export default Terminabfrage