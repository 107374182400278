import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import FullscreenApp from './FullscreenApp';

if (document.getElementsByClassName('twodo__open-termine')) {

	const reactRootElements = document.getElementsByClassName('twodo__open-termine')
	for (let i = 0; i < reactRootElements.length; i++){
		const root = ReactDOM.createRoot(reactRootElements[i])
		root.render(
			<React.StrictMode>
				<App el={reactRootElements[i]}  />
		  	</React.StrictMode>
        )
	}
}

if (document.getElementsByClassName('twodo__termine-full')) {

	const reactFullRootElements = document.getElementsByClassName('twodo__termine-full')
	for (let i = 0; i < reactFullRootElements.length; i++){
		const root = ReactDOM.createRoot(reactFullRootElements[i])
		root.render(
			<React.StrictMode>
				<FullscreenApp el={reactFullRootElements[i]}  />
		  	</React.StrictMode>
        )
	}
}
