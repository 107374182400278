import React from 'react'
import StepWrapper from '../Terminabfrage/StepWrapper'
import TerminFormular from '../Forms/TerminFormular'


const Step4= () => {
	
	return (
		<StepWrapper number="04">
				<><p><strong>Ihre Daten:</strong><br/>Um Ihren Wunschtermin bestätigen zu können, benötigen wir Ihre Daten. Sie erhalten innerhalb eines Werktags eine Rückmeldung.</p><br/>
					<div className="twodo__lightblue--container client__container">
						<TerminFormular/>
					</div>
				</>
		</StepWrapper>
	)
}

export default Step4