import { useContext } from 'react'
import GlobalStyle from './Components/Styles/GlobalStyles';
import { ClientContext } from './Components/Context/StateProvider'
import Vars from './Components/Styles/Vars';
import Terminabfrage from './Components/Terminabfrage/Terminabfrage';
import { useEffect } from 'react';

const FullAppContent = () => {
	
	const [client] = useContext(ClientContext)
	useEffect(() => { 
		console.log(client);
	}, [client])


	







	return (
		<div className="App">
			<GlobalStyle custom={Vars(client[0])} />
			<div className="twodo__modal--content twodo__modal--full">
				<Terminabfrage />
				
			</div>
		</div>
	)
}

export default FullAppContent
