const calculateBusinessDays = (start, end) => {

	// initial total
	let totalBusinessDays = 0;
	const startCopy = start
	const endCopy = end

	// normalize both start and end to beginning of the day
	// startCopy.setHours(0,0,0,0);
	// endCopy.setHours(0,0,0,0);

	const current = startCopy;
	current.setDate(current.getDate() + 1);
	let day;
	// loop through each day, checking
	while (current <= endCopy) {
		day = current.getDay();
		if (day >= 1 && day <= 5) {
			++totalBusinessDays;
		}
		current.setDate(current.getDate() + 1);
	}
	return totalBusinessDays;
}

export default calculateBusinessDays