import React from 'react'

const Button = (props) => {
	return (
		<>
		{
			props.href ? 
				<a className={`main__button ${props.className}`} onClick={props.click} href={props.href}>{props.children}</a>
				:
				<button className={`main__button ${props.className ? props.className : ''}`} onClick={props.click}>{props.children}</button>

			}
		</>	  
  )
}

export default Button
