import React, {useContext} from 'react'
import { AnmeldungContext } from '../Context/StateProvider'


const ClientSelection = (props) => {

	const [anmeldung, setAnmeldung] = useContext(AnmeldungContext)



	const handleClick = () => { 
		setAnmeldung({
			...anmeldung,
			filiale: props.filiale,
			geschaeft: props.name,
			clientid: props.id,
			strasse: props.strasse,
			plz: props.plz,
			ort: props.ort,
			email: props.email,
			blockedDays: props.client.buchungsvorlauf,
			open: [
				props.client.mo,
				props.client.di,
				props.client.mi,
				props.client.do,
				props.client.fr,
				props.client.sa
			],
			mo: props.client.mo,
			di: props.client.di,
			mi: props.client.mi,
			do: props.client.do,
			fr: props.client.fr,
			sa: props.client.sa,
			step: 3
		})
	}

	return (
		<div className='twodo__client-select'>
			<div className="twodo__client-content">
				<p><strong>{props.name} {props.filiale}</strong></p>
				<p>{props.strasse}, {props.plz} {props.ort}</p>
			</div>
			<div className="twodo__client-cta">
				<p onClick={handleClick} className="twodo__selector twodo__primary-color">Auswählen</p>
			</div>
	</div>
	)
}

export default ClientSelection