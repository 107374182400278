import { AnmeldungProvider } from './Components/Context/StateProvider'

import FullAppContent from './FullAppContent';




function FullscreenApp(props) {

	return (
		<AnmeldungProvider id={props.el.dataset.client}  button={props.el.dataset.button}>
			<FullAppContent el={props.el} />
		</AnmeldungProvider>
	);
}

export default FullscreenApp;

