import StepWrapper from "./StepWrapper"


const TerminSelection = ({ step, anmeldung }) => {

	const values = [
		{
			text: "Ihr Anliegen:",
			result: `<strong>${anmeldung.terminart}</strong>`
		},
	]


	return (

			<StepWrapper number="01">
				<>
					<p>{values[0].text}</p>
					<p dangerouslySetInnerHTML={{__html: values[0].result}}></p>
	
				</>
			</StepWrapper>
	
	)
}

export default TerminSelection