import React from 'react'
import StepWrapper from '../Terminabfrage/StepWrapper'
import ClientSelection from '../Terminabfrage/ClientSelection'
import PrimaryButton from '../Button/PrimaryButton'


const Step2 = ({ setAnmeldung, anmeldung, clients }) => {

	const resetStore = () => {
		setAnmeldung({
			...anmeldung,
			clientid: '',
			step: 2,
			filiale: ''
		})
	}

	const setSingleAnmeldung = () => {
		setAnmeldung({
			...anmeldung,
			step: anmeldung.step + 1,
			geschaeft: clients[0].unternehmen,
			filiale: clients[0].filiale,
			clientid: clients[0].id,
			strasse: clients[0].strasse,
			plz: clients[0].plz,
			ort: clients[0].ort,
			email: clients[0].email,
			blockedDays: clients[0].buchungsvorlauf,
			open: [
				clients[0].mo,
				clients[0].di,
				clients[0].mi,
				clients[0].do,
				clients[0].fr,
				clients[0].sa
			],
			mo: clients[0].mo,
			di: clients[0].di,
			mi: clients[0].mi,
			do: clients[0].do,
			fr: clients[0].fr,
			sa: clients[0].sa,
		})
	}



	return (
		<StepWrapper number="02">

			{/* Wenn keine Filiale gewählt wurde und mehr als 1 Filialen vorhanden sind */}
			{anmeldung.filiale === "" && clients.length > 1 ?
				<><p>Bitte wählen Sie Ihr Fachgeschäft:</p><br/>
					<div className="twodo__lightblue--container client__container">
						{clients.map((client, index) => (
							<ClientSelection
								key={client.id}
								filiale={client.filiale}
								name={client.unternehmen}
								email={client.email}
								strasse={client.strasse}
								plz={client.plz}
								ort={client.ort}
								client={client}
								id={client.id}
							/>
						))
						}
					</div>
				</>
				:
				clients.length === 1 ?
					<>
					<strong>{clients[0].unternehmen}</strong>
					<p>{clients[0].filiale ? clients[0].filiale + ', ' : ''} {clients[0].strasse}</p>
						<p>{clients[0].plz} {clients[0].ort}</p>
						{anmeldung.step === 2 && 
							<><br /><PrimaryButton click={setSingleAnmeldung}>Weiter</PrimaryButton><br /><br /></>
						}

				</> :
				<>
					<strong>Ihr Fachgeschäft:</strong>
					<div className="twodo__selected-client">
						<strong>{anmeldung.geschaeft} {anmeldung.filiale ? anmeldung.filiale : ''}</strong>
						<p> {anmeldung.strasse}</p>
						<p>{anmeldung.plz} {anmeldung.ort}</p><br />

						<p onClick={resetStore} className="twodo__selector twodo__accent-color">Anderen Experten wählen</p>
					</div>
				</>


			}


		</StepWrapper>
	)
}

export default Step2