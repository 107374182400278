export const renderDate = (d) => {
	const weekDays = ['Sonntag','Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
	const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
	const weekDay = weekDays[d.getDay()]
	const day = d.getDate()
	const month = monthNames[d.getMonth()]
	const year = d.getFullYear()

	return `${weekDay}, ${day}. ${month} ${year}`
}
export const renderDatabaseDate = (d) => {
	const day = d.getDate()
	const month = d.getMonth() + 1
	const year = d.getFullYear()
	const minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
	const hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()

	return `${year}-${month}-${day} ${hours}:${minutes}:00`
}

export const renderTime = (d) => {
	const minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
	const hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
	return `${hours}:${minutes} Uhr`
}
export const renderDatabaseTime = (d) => {
	const minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
	const hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
	return `${hours}:${minutes}:00`
}