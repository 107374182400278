const Vars = (props) => {

	return {
		primary: props ? props.accentcolor : '#333',
		buttonFont: props ? props.buttonfontcolor : '#fff',
		textColor: props ? props.textcolor : '#000',
	}
	
}

export default Vars