import React, { useState, createContext, useEffect } from "react";
import axios from 'axios'





export const ClientContext = createContext()
export const AnmeldungContext = createContext()
// export const TermineContext = createContext()
// eslint-disable-next-line
const deconstructTimes = (time) => {
	// let t = time.split(',')
	// const days = t.map(day => {
	// 	let el = day.split('|')
	// 	switch (el[0]) {
	// 		case 'MO':
	// 			el[0] = 1
	// 			break
	// 		case 'DI':
	// 			el[0] = 2
	// 			break
	// 		case 'MI':
	// 			el[0] = 3
	// 			break
	// 		case 'DO':
	// 			el[0] = 4
	// 			break
	// 		case 'FR':
	// 			el[0] = 5
	// 			break
	// 		case 'SA':
	// 			el[0] = 6
	// 			break
	// 		default:
	// 			el[0] = 0
	// 	}
	// 	el = [el[0], el[1].split('+')]
	// 	return el
	// })
	// return days

}

export const ClientProvider = (props) => {

	let clientApi = `https://api.twodo.online/termine/users/read.php?kunde=${props.id}`

	const [clients, setClients] = useState([])

	useEffect(() => {
		axios.get(clientApi).then(res => {
			const data = res.data.map(client => {
				return {
					...client,
					other: client.other ? JSON.parse(client.other) : '',
					buttonText: props.button
				}
			})
			setClients(data)
		})


	}, [clientApi, props.button])
	return (
		<ClientContext.Provider value={[clients]}>
			{props.children}
		</ClientContext.Provider>
	)
}

// export const TermineProvider = (props) => { 
// 	let termineApi = `https://api.twodo.online/termine/bookings/read.php?id=${props.clientid}`
	
// 	const [termine, setTermine] = useState([])

// 	useEffect(() => {
// 		axios.get(termineApi).then(res => setTermine(res.data))
// 	}, [])

// 	return (
// 		<TermineContext.Provider value={[termine]}>
// 			{props.children}
// 		</TermineContext.Provider>
// 	)

// }







export const AnmeldungProvider = (props) => {

	const [anmeldung, setAnmeldung] = useState({
		step: 1,
		token: 'yQFwz[J(g9%$FEmo4E]?',
		clientid: '',
		geschaeft: '',
		filiale: '',
		strasse: '',
		plz: '',
		ort: '',
		termin: '',
		uhrzeit: '',
		anrede: '',
		vorname: '',
		nachname: '',
		email: '',
		telefon: '',
		terminart: ''
	})
	return (
		<AnmeldungContext.Provider value={[anmeldung, setAnmeldung]}>
			<ClientProvider id={props.id} button={props.button ? props.button : ''}>
				{/* <TermineProvider clientid={props.clientid}> */}
					{props.children}
				{/* </TermineProvider> */}
			</ClientProvider>
		</AnmeldungContext.Provider>
	)
}


