import React from 'react'
import StepWrapper from '../Terminabfrage/StepWrapper'
import DateSelector from '../Terminabfrage/DateSelector'


const Step3= () => {
	
	return (
		<StepWrapper number="03">
			<>
				<DateSelector/>
			</>
		</StepWrapper>
	)
}

export default Step3